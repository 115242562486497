import axios from "axios";

const projectManageAxios = axios.create({
    baseURL: "https://projectmanagedev.azurewebsites.net/",
    // baseURL: 'http://localhost:5035/',
    // baseURL: "https://localhost:7230/",
});

const humanResourcesAxios = axios.create({
    baseURL: "https://humanresourcesbackenddev.azurewebsites.net/",
    // baseURL: 'http://localhost:5035/',
    // baseURL: "https://localhost:7230/",
});

const systemsIntegrationAxios = axios.create({
    baseURL: "https://systemsintegrationbackenddev.azurewebsites.net/",
    // baseURL: 'http://localhost:5055/',
    // baseURL: "https://localhost:7230/",
});

export {
  projectManageAxios,
  humanResourcesAxios,
  systemsIntegrationAxios
};