import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import Typography from "@mui/material/Typography";
import { Alert, Box, Grid, ToggleButton, ToggleButtonGroup, Stack, TextField, Button, MenuItem, FormControl, InputLabel, Select, Snackbar } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DatePickerValue from "../../../components/DatePicker";
import { UnitConfiguration } from "../../../components/UnitConfiguration";
import { projectManageAxios as axios } from '../../../utils/AxiosUtility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function OnboardingTask(props) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [project, setProject] = useState(null);
  const [taskID, setTaskID] = useState("");
  const [pm, setPM] = useState("Allen Yokom");
  const [cs, setCentralStation] = useState("Not Selected");
  const [am, setAccountManager] = useState("Not Selected");
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [siteVisit, setSiteVisit] = useState(false);
  const [siteVisitDate, setSiteVisitDate] = useState(null);
  const [siteVisitCom, setSiteVisitCom] = useState(false);
  const [siteVisitComDate, setSiteVisitComDate] = useState(null);
  const [approval, setApproval] = useState(false);
  // eslint-disable-next-line
  const [approvalDate, setApprovalDate] = useState(new Date());
  const [preApproval, setPreApproval] = useState(false);
  const [finalApproval, setFinalApproval] = useState(false);
  const [prelimDesign, setPrelimDesign] = useState(false);
  const [finalDesign, setFinalDesign] = useState(false);
  const [installVal, setInstallVal] = useState(0);
  const [rmrVal, setrmrVal] = useState(0);
  const [contract, setContract] = useState(false);
  const [unitConfig, setUnitConfig] = useState(0);
  const [units, setUnits] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [selectedFinalDesign, setSelectedFinalDesign] = useState("None");
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line
  const [updatedChanges, setUpdatedChanges] = useState({
    "acknowledgementDate": null,
    "pmAssigned": null,
    "corpApprovalDate": null,
    "contractReceived": null,
    "centralStation" : null,
    "csAccountManager" : null,
  });

  const handleAckChange = (event, ack) => {
    if (ack !== null) {
      setAcknowledgement(ack);
      setUpdatedChanges((prevState) => ({
        ...prevState,
        "acknowledgementDate": "true",
      }));
    }
  };

  const handlePmSelect = (event) => {
    setPM(event.target.value);
    setUpdatedChanges((prevState) => ({
      ...prevState,
      "pmAssigned": "true",
    }));
  };

  const handleCentralStationSelect = (event) => {
    setCentralStation(event.target.value);
    setUpdatedChanges((prevState) => ({
      ...prevState,
      "centralStation": "true",
    }));
  };

  const handleAccountManagerSelect = (event) => {
    setAccountManager(event.target.value);
    setUpdatedChanges((prevState) => ({
      ...prevState,
      "csAccountManager": "true",
    }));
  };

  const handleSiteChange = (event, site) => {
    if (site !== null) {
      setSiteVisit(site);
      setShowDatePicker(site === true);
    }
  };

  const handleSiteComChange = (event, site) => {
    if (site !== null) {
      setSiteVisitCom(site);
      setSiteVisitComDate(new Date());
    }
  };

  const handlePrelimDesign = (event, design) => {
    if (design !== null) {
      setPrelimDesign(design);
    }
  };

  const handleFinalDesign = (event) => {
    if (event !== null) {
      setSelectedFinalDesign(event.target.value);
    }
  };

  const handleApproval = (event, approval) => {
    if (approval !== null) {

      setApproval(approval);
      setApprovalDate(new Date(new Date().getTime()));
    }
  };

  const handlePreApproval = (event, approval) => {
    if (approval !== null) {
      setPreApproval(approval);
    }
  };

  const handleFinalApproval = (event, approval) => {
    if (approval !== null) {
      setFinalApproval(approval);
      setUpdatedChanges((prevState) => ({
        ...prevState,
        "corpApprovalDate": "true",
      }));
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleConfirmOpen = () => {
    setConfirm(true);
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleConfirm = () => {
    if (rmrVal > 0 && installVal > 0) {
      setContract(true);
      setUpdatedChanges((prevState) => ({
        ...prevState,
        "contractReceived": "true",
      }));

      let update = {
        projectId: searchParams.get("id"),
        rmrValue: rmrVal,
        installationPrice: installVal
      }

      axios.post("/api/Projects/updatePriceValues", update)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
          alert("Error updating task.");
        });
    }
    setConfirm(false);
  };

  const handleContract = (event, contract) => {
    if (contract !== null) {
      if (contract) {
        handleConfirmOpen();
      } else {
        setContract(contract);
        setUpdatedChanges((prevState) => ({
          ...prevState,
          "contractReceived": "false",
        }));
      }
    }
  };

  const handleUnitConfigUpdate = (updatedUnits) => {
    setUnitConfig(updatedUnits.length);
    setUnits(updatedUnits);
  }

  const handleUnitConfigIncrease = (event, type) => {
    let val = parseInt(unitConfig + 1);
    setUnitConfig(val);
    let unitSets = [];
    for (let i = 0; i < val; i++) {
      if (units[i]) {
        unitSets.push(units[i]);
      }
      else {
        unitSets.push({
          name: "Unit " + (i + 1),
          type: type,
          id: 0,
          projectId: searchParams.get("id"),
          value: i,
          riser: 0,
          panel: 0,
          riserIL: false,
          power: null,
          coldPack: false,
          detectZone: 0,
          detectType: [],
          currentType: 0,
          detectOZone: 0,
          detectOType: [],
          currentOType: 0,
          mast: false,
          conveyor: false,
          viewStation: 0,
          ptp: false,
          thermal: 0,
          flame: 0,
          smoke: 0,
          eButton: 0,
          fd: false,
          interconnect: false,
          international: false,
          primary4G: false,
          f500: 8
        });
      }
    }
    setUnits(unitSets);
  };

  const handleSubmit = () => {
    let updatedTask = {
      "projectTaskObId": taskID,
      "projectId": searchParams.get("id"),
      "aknowledgementDate": acknowledgement ? new Date(new Date().getTime()) : null,
      "pmAssigned": pm ?? "Allen Yokom",
      "siteVisitReq": siteVisit ?? false,
      "siteVisitDate": siteVisitDate ?? null,
      "siteVisitComplete": siteVisitCom ?? null,
      "siteVisitCompleteDate": siteVisitComDate,
      "finalDesign": selectedFinalDesign !== "None" && selectedFinalDesign !== null ? true : false,
      "finalDesignDate": finalDesign ? new Date(new Date().getTime()) : null,
      "pEngineerApprover": pm ?? "Allen Yokom",
      "pEngineerApprovedDate": preApproval ? new Date(new Date().getTime()) : null,
      "fEngineerApprover": pm ?? "Allen Yokom",
      "fEngineerApprovedDate": approval ? new Date(new Date().getTime()) : null,
      "corpApprovalDate": finalApproval ? new Date(new Date().getTime()) : null,
      "contractReceived": contract ? new Date(new Date().getTime()) : null,
      "centralStation": cs ?? "Not Selected",
      "csAccountManager": am ?? "Not Selected",
    }

    updatedTask = { ...project, ...updatedTask }
    axios.post("/api/Projects/updateObTask", updatedTask)
    .then((response) => {
      props.onObTaskSubmit(true);
      setShowSnackbar(true);
    })
    .catch((error) => {
      console.log(error);
      alert("Error updating task.");
    });

    if (selectedFinalDesign === "None" || selectedFinalDesign == null) {
    axios.post("/api/Onboarding/clearFinalDesign")
    .then((response) => {
      setShowSnackbar(true);
    })
    .catch((error) => {
      console.log(error);
      alert("Error clearing the final design.");
    });
    }
    else {
      const selectedDocument = documentList.find(doc => doc.documentLink === selectedFinalDesign);
    axios.post(`/api/Onboarding/setFinalDesign/${selectedDocument.siteDocumentId}`)
    .then((response) => {
      setShowSnackbar(true);
    })
    .catch((error) => {
      console.log(error);
      alert("Error setting the final design.");
    });
    }

        let dataToUpload = {
          "projectId": updatedTask.projectId,
          "acknowledgementDate": updatedTask.aknowledgementDate ?? null,
          "pmAssigned": acknowledgement ? updatedTask.pmAssigned : null,
          "siteVisitDate": siteVisit ? updatedTask.siteVisitDate : null,
          "engineeringApproval": updatedTask.approvalDate ?? null,
          "corpApprovalDate": updatedTask.corpApprovalDate ?? null,
          "contractReceived": updatedTask.contractReceived ?? null
        }

        axios.patch("https://externalapiinteractordev.azurewebsites.net/api/HubspotTest/uploadData", dataToUpload)
          .then((response) => {
            setShowSnackbar(true);
          })
          .catch((error) => {
            console.log(error);
            alert("Error updating task in Hubspot.");
          });
  };

  const availablePMs = [
    {
      id: 0,
      name: "Allen Yokom",
    },
    {
      id: 1,
      name: "Kiley Galesewicz",
    },
    {
      id: 2,
      name: "Maurice Johnson",
    },
    {
      id: 3,
      name: "Roman Dowhaniuk",
    },
  ]

  const centralStationOptions = [
    {
      id: 0,
      name: "Not Selected",
    },
    {
      id: 1,
      name: "COPS",
    },
    {
      id: 2,
      name: "Acadian",
    },
  ]

  const accountManagerOptions = [
    {
      id: 0,
      name: "Not Selected",
    },
    {
      id: 1,
      name: "Sasha Hartje",
    },
    {
      id: 2,
      name: "Joe Chudy",
    },
    {
      id: 3,
      name: "Jeff Bramlage",
    },
  ]

  const filteredDocuments = documentList.filter(doc =>
    ['Preliminary Design', 'Major Change', 'Minor Change'].includes(doc.fileType)
  );

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  const loadParams = (project) => {
    setTaskID(project["projectTaskObId"]);
    if (project["siteVisitReq"] === true) {
      if (project["siteVisitCompleteDate"]) {
        setSiteVisitComDate(project["siteVisitCompleteDate"] ? offsetDate(new Date(project["siteVisitCompleteDate"])) : null);
      }
      if (project["siteVisitDate"]) {
        setSiteVisitDate(project["siteVisitDate"] ? offsetDate(new Date(project["siteVisitDate"])) : null);
      }
      setSiteVisitCom(project["siteVisitCompleteDate"] ? true : false);
      setSiteVisit(true);
      setShowDatePicker(true);
    }
    else if (project["siteVisitReq"] === false) {
      setSiteVisit(false);
    }
    setAcknowledgement(project["aknowledgementDate"] ? true : false);
    setApproval(project["fEngineerApprovedDate"] ? true : false);
    setPreApproval(project["pEngineerApprovedDate"] ? true : false);
    setFinalApproval(project["corpApprovalDate"] ? true : false);
    setPrelimDesign(project["prelimDesign"] ? true : false);
    setFinalDesign(project["finalDesign"] ? true : false);
    setContract(project["contractReceived"] ? true : false);
    setPM(project["pmAssigned"]);
    setProject(project);
    setLoading(false);
  };

  const loadFinalDesign = (documents) => {
    documents.forEach(doc => {
      if (doc.finalDesignDoc) {
        setSelectedFinalDesign(doc.documentLink);
      }
    });
  };  

  const loadUnits = (dbUnits) => {
    let i = 0
    setUnitConfig(dbUnits.length);
    let unitSets = [];
    dbUnits.forEach(unit => {
      let zones = [];
      let onlyZones = [];

      unitSets.push({
        name: unit.productName ?? "Unit " + (i + 1),
        type: unit.productType,
        id: unit.productId,
        projectId: searchParams.get("id"),
        value: i,
        riser: unit.productConfigurationDTOs[0].riserCount ?? 0,
        panel: unit.productConfigurationDTOs[0].panelCount ?? 0,
        coldPack: unit.productConfigurationDTOs[0].coldWeatherPackage ?? false,
        detectZone: zones.length,
        detectType: zones,
        currentType: 0,
        detectOZone: onlyZones.length,
        detectOType: onlyZones,
        currentOType: 0,
        power: unit.productConfigurationDTOs[0].powerType,
        riserIL: unit.productConfigurationDTOs[0].riserIndicatorLight ?? false,
        mast: unit.productConfigurationDTOs[0].mastOption ?? false,
        conveyor: unit.productConfigurationDTOs[0].conveyorStop ?? false,
        viewStation: unit.productConfigurationDTOs[0].viewingStation ?? 0,
        ptp: unit.productConfigurationDTOs[0].pointToPoint ?? false,
        thermal: unit.productConfigurationDTOs[0].thermalCameraCount ?? 0,
        flame: unit.productConfigurationDTOs[0].flameDetectorCount ?? 0,
        smoke: unit.productConfigurationDTOs[0].smokeDetectorCount ?? 0,
        eButton: unit.productConfigurationDTOs[0].ebuttonCount ?? 0,
        fd: unit.productConfigurationDTOs[0].fdConnects ?? false,
        interconnect: unit.productConfigurationDTOs[0].interconnect ?? false,
        international: unit.productConfigurationDTOs[0].international ?? false,
        primary4G: unit.productConfigurationDTOs[0]._4gPrimary ?? false,
        f500: unit.productConfigurationDTOs[0].f500Count ?? 0
      });
      i++;
    });
    setUnits(unitSets);
  }

  const fetchDocuments = () => {
    axios
      .get(`/api/Onboarding/documentList/${searchParams.get("id")}`)
      .then((response) => {
        const documents = Array.isArray(response.data)
          ? response.data
          : [];
        setDocumentList(documents);
        loadFinalDesign(documents);
      })
      .catch((error) => {
        console.log(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching document list.");
        }
      });
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    const endpoint = 'api/Projects/obtask/' + searchParams.get("id");
    setLoading(true);
    axios.get(endpoint)
      .then((response) => {
        loadParams(response.data);
        const endpoint = 'api/Projects/products/' + searchParams.get("id");
        axios.get(endpoint)
          .then((response) => {
          loadUnits(response.data);
          })
          .catch((error) => {
            console.log(error)
            alert("Error retreiving data.");
          });
      })
      .catch((error) => {
        console.log(error)
        alert("Error retreiving data.");
      });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ?
        <Box sx={{ minHeight: "1137px" }} justifyContent="center" alignContent="center">
          <CircularProgress variant="indeterminate" />
        </Box>
        :
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Tasks
          </Typography>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Acknowledgment
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <ToggleButtonGroup
                  color="primary"
                  value={acknowledgement}
                  exclusive
                  onChange={handleAckChange}
                  aria-label="Ack"
                  sx={{ paddingRight: "10%" }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>

                {acknowledgement
                  ?
                  <FormControl sx={{ width: "40%" }}>
                    <InputLabel id="pm-label">PM</InputLabel>
                    <Select
                      labelId="pm-label"
                      id="pm-select"
                      value={pm}
                      label="Unit"
                      onChange={handlePmSelect}
                      sx={{ padding: '0px', maxHeight: "45px" }}
                    >
                      {availablePMs.map((pm) => (
                        <MenuItem key={pm.id} value={pm.name}>
                          {pm.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  :
                  null
                }
              </Box>


            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Site Visit
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <ToggleButtonGroup
                  color="primary"
                  value={siteVisit}
                  exclusive
                  onChange={handleSiteChange}
                  aria-label="Site Visit"
                  sx={{ paddingRight: "10%" }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>

                {showDatePicker
                  ?
                  !siteVisitCom
                    ?
                    <DatePickerValue onChange={setSiteVisitDate} value={siteVisitDate} label={"Scheduled Visit Date"} />
                    :
                    <DatePickerValue onChange={setSiteVisitDate} value={siteVisitDate} disabled label={"Scheduled Visit Date"} />
                  :
                  null
                }
              </Box>
            </Stack>
          </Grid>

          {showDatePicker && (
            <Grid item xs={12} style={{ paddingBottom: '5%' }}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h6"
                  style={{ minWidth: '35%' }}
                >
                  Site Visit Completed
                </Typography>

                <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={siteVisitCom}
                    exclusive
                    onChange={handleSiteComChange}
                    aria-label="Site Visit Completed"
                    sx={{ paddingRight: "10%" }}
                  >
                    <ToggleButton color="success" value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Preliminary Design
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={prelimDesign}
                exclusive
                onChange={handlePrelimDesign}
                disabled
                aria-label="PreDesign"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Prelim Eng Approval
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={preApproval}
                exclusive
                onChange={handlePreApproval}
                aria-label="Apr"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Final Design
              </Typography>

              <FormControl sx={{ width: "40%" }}>
                <InputLabel id="finalDesign-label">Final Design</InputLabel>
                <Select
                  labelId="finalDesign-label"
                  id="finalDesign-select"
                  value={selectedFinalDesign}
                  label="Final Design"
                  onChange={handleFinalDesign}
                  sx={{ padding: '0px', maxHeight: "45px" }}
                >
                  <MenuItem key={0} value={"None"}>None</MenuItem>
                  {filteredDocuments.map((doc) => (
                    <MenuItem key={doc.id} value={doc.documentLink}>
                      {`${doc.fileType} ${doc.versionNo}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Final Eng Approval
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={approval}
                exclusive
                onChange={handleApproval}
                aria-label="Approval"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                BSU Configuration
              </Typography>

              <TextField
                label="Unit Count"
                name="unitTotal"
                disabled
                value={units.filter(i => i.type === "BSU").length}
                style={{ minWidth: "26%" }}
              />

              <Box sx={{ flexGrow: '1', minWidth: "30%", paddingLeft: "2%", alignContent: "space-evenly" }}>
                <Button variant="contained" sx={{ mr: 2 }} onClick={(e) => { handleUnitConfigIncrease(e, "BSU") }}>Add Unit</Button>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                OnWatch Configuration
              </Typography>

              <TextField
                type="number"
                label="Unit Count"
                name="unitNum"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                value={units.filter(i => i.type === "OW").length}
                disabled
                style={{ minWidth: "26%" }}
              />

              <Box sx={{ flexGrow: '1', minWidth: "30%", paddingLeft: "2%", alignContent: "space-evenly" }}>
                <Button variant="contained" sx={{ mr: 2 }} onClick={(e) => { handleUnitConfigIncrease(e, "OW") }}>Add Unit</Button>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Early Detection Configuration
              </Typography>

              <TextField
                type="number"
                label="Unit Count"
                name="unitNum"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                value={units.filter(i => i.type === "ED").length}
                disabled
                style={{ minWidth: "26%" }}
              />

              <Box sx={{ flexGrow: '1', minWidth: "30%", paddingLeft: "2%", alignContent: "space-evenly" }}>
                <Button variant="contained" sx={{ mr: 2 }} onClick={(e) => { handleUnitConfigIncrease(e, "ED") }}>Add Unit</Button>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Continuous Flow Configuration
              </Typography>

              <TextField
                type="number"
                label="Unit Number"
                name="unitNum"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                value={units.filter(i => i.type === "CF").length}
                disabled
                style={{ minWidth: "26%" }}
              />

              <Box sx={{ flexGrow: '1', minWidth: "30%", paddingLeft: "2%", alignContent: "space-evenly" }}>
                <Button variant="contained" sx={{ mr: 2 }} onClick={(e) => { handleUnitConfigIncrease(e, "CF") }}>Add Unit</Button>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <UnitConfiguration
                unitChange={handleUnitConfigUpdate}
                units={units}
                projectId={searchParams.get("id")}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Corporate Approval
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={finalApproval}
                exclusive
                onChange={handleFinalApproval}
                aria-label="Final Approval"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Contract Received
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={contract}
                exclusive
                disabled={project ? project.statusOb === "Completed" : true}
                onChange={handleContract}
                aria-label="Final Design"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: "1%" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSubmit}>Save</Button>
            </Box>
          </Grid>

          <Dialog
            open={confirm}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleConfirmClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Confirm Contract Recieved?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Setting this to complete will set the status of this task
                to complete and open the Site Prep and Site Coordination tasks.

                Please enter the following information before saving.
              </DialogContentText>
              <TextField
                fullWidth
                label="RMR Value"
                value={rmrVal}
                onChange={(e) => setrmrVal(e.target.value)}
                name="rmrValue"
                id="rmr-value-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom,
                }}
                sx={{ mt: 2, mb: 2 }}
                variant="outlined"
              />

              <TextField
                fullWidth
                label="Install Value"
                value={installVal}
                onChange={(e) => setInstallVal(e.target.value)}
                name="installValue"
                id="install-price-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom,
                }}
                sx={{ mt: 2, mb: 4 }}
                variant="outlined"
              />

              <FormControl sx={{ width: "50%", paddingRight: "1%", }}>
                <InputLabel id="cs-label">Central Station</InputLabel>
                <Select
                  labelId="cs-label"
                  id="cs-select"
                  value={cs}
                  label="Central Station"
                  onChange={handleCentralStationSelect}
                  sx={{ padding: '0px', maxHeight: "50px" }}
                >
                  {centralStationOptions.map((cs) => (
                    <MenuItem key={cs.id} value={cs.name}>
                      {cs.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: "50%", paddingRight: "0%", }}>
                <InputLabel id="am-label">Account Manager</InputLabel>
                <Select
                  labelId="am-label"
                  id="am-select"
                  value={am}
                  label="Account Manager"
                  onChange={handleAccountManagerSelect}
                  sx={{ padding: '0px', maxHeight: "50px" }}
                >
                  {accountManagerOptions.map((am) => (
                    <MenuItem key={am.id} value={am.name}>
                      {am.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose}>Cancel</Button>
              <Button onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={showSnackbar}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Saved Successfully!
            </Alert>
          </Snackbar>
        </>
      }
    </>
  );
}
